import React from "react"
import { PageProps, Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCloud,
  faUserFriends,
  faServer,
  faCloudUploadAlt,
  faSearchDollar,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/PageLayout"
import SEO from "../../components/seo"
import Hero from "../../components/Hero"
import SalesPoint from "../../components/SalesPoint"
import ContactForm from "../../components/ContactForm"

const CloudConsultancy = (props: PageProps) => (
  <Layout
    title={"Cloud Consultancy"}
    hero={
      <Hero
        title="Cloud Consultancy"
        subtitle="We can help you no matter where you are on your cloud journey."
        icon={
          <FontAwesomeIcon icon={faCloud} color={"var(--white)"} size={"4x"} />
        }
      />
    }
  >
    <SEO title="Cloud Consultancy" />
    <Row>
      <Col sm={12} md={12} lg={8}>
        <div>
          <SalesPoint
            icon={faUserFriends}
            title={"Solution architecture that puts people first"}
          >
            It's not enough to focus solely on the technical challenges of a
            given problem anymore. The teams and structures that form around the
            problem need to be given just as much thought. We can work with you
            to build software solutions that not only solve your business
            challenges, but that prioritise team dynamics and well-being.
          </SalesPoint>

          <SalesPoint
            icon={faServer}
            title={"Slash your costs with Serverless"}
          >
            <div>
              Serverless computing allows you to truly unlock the power and cost
              benefits of the cloud. In the Serverless model, you don't spend
              time and money managing and running servers. Instead, you rely on
              cloud providers like AWS and Azure to expertly manage this, 24x7.
              Serverless architectures are billed on a pay-as-you-go basis,
              meaning you can realise significant savings on suitable workloads.
            </div>
            <Link
              className="mt-4 btn btn-primary btn-sm"
              to={"/services/serverless"}
            >
              Find out more about Serverless &raquo;
            </Link>
          </SalesPoint>

          <SalesPoint
            icon={faCloudUploadAlt}
            title={"Greater resiliency and peace of mind in the cloud"}
          >
            Tired of downtime, costs and the potential for security breaches? It
            is no longer true that a cloud migration of your business-critical
            workloads is going to be more costly or less secure. We can help you
            move your workloads away from expensive on-premise servers to the
            cloud, while respecting your data protection, security and
            regulatory requirements.
          </SalesPoint>

          <SalesPoint
            icon={faSearchDollar}
            title={"Understand how your application actually works"}
          >
            Did you know that{" "}
            <a href="https://about.gitlab.com/developer-survey/">
              in a survey conducted by GitLab
            </a>
            , over half of the respondents said they didn't know if a change
            they made affected the performance of their cloud application? We
            can work with your teams to build tracing and metrics into your
            software, and advise on dashboards, wallboards and alerts to make
            this visible to your engineering teams.
          </SalesPoint>
        </div>
      </Col>
      <Col>
        <ContactForm />
      </Col>
    </Row>
  </Layout>
)

export default CloudConsultancy
